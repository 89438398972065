exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-become-a-partner-js": () => import("./../../../src/templates/become-a-partner.js" /* webpackChunkName: "component---src-templates-become-a-partner-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-custom-post-js": () => import("./../../../src/templates/customPost.js" /* webpackChunkName: "component---src-templates-custom-post-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-insights-listing-js": () => import("./../../../src/templates/insights-listing.js" /* webpackChunkName: "component---src-templates-insights-listing-js" */),
  "component---src-templates-inthenews-js": () => import("./../../../src/templates/inthenews.js" /* webpackChunkName: "component---src-templates-inthenews-js" */),
  "component---src-templates-inthenews-listing-js": () => import("./../../../src/templates/inthenews-listing.js" /* webpackChunkName: "component---src-templates-inthenews-listing-js" */),
  "component---src-templates-investments-js": () => import("./../../../src/templates/investments.js" /* webpackChunkName: "component---src-templates-investments-js" */),
  "component---src-templates-news-listing-js": () => import("./../../../src/templates/news-listing.js" /* webpackChunkName: "component---src-templates-news-listing-js" */),
  "component---src-templates-our-partners-js": () => import("./../../../src/templates/our-partners.js" /* webpackChunkName: "component---src-templates-our-partners-js" */),
  "component---src-templates-our-people-js": () => import("./../../../src/templates/ourPeople.js" /* webpackChunkName: "component---src-templates-our-people-js" */),
  "component---src-templates-ourpeople-listing-js": () => import("./../../../src/templates/ourpeople-listing.js" /* webpackChunkName: "component---src-templates-ourpeople-listing-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-details-js": () => import("./../../../src/templates/partnerDetails.js" /* webpackChunkName: "component---src-templates-partner-details-js" */),
  "component---src-templates-perspective-js": () => import("./../../../src/templates/perspective.js" /* webpackChunkName: "component---src-templates-perspective-js" */),
  "component---src-templates-solution-detail-js": () => import("./../../../src/templates/solutionDetail.js" /* webpackChunkName: "component---src-templates-solution-detail-js" */),
  "component---src-templates-what-we-do-js": () => import("./../../../src/templates/what-we-do.js" /* webpackChunkName: "component---src-templates-what-we-do-js" */)
}

